import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
// import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent, useEffect, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import axios from "axios";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { fieldStyles, formStyles, introStyles, titleStyles } from "./styles/PreEngagementFormPhase.styles";

export const PreEngagementFormPhase = () => {
    const { hostname } = window.location;
    let apiPsicotest = "";
    if (hostname === "psicotest.app") {
        apiPsicotest = "https://psicotest.app/apipsicotest/";
    } else {
        apiPsicotest = "https://psicotestqa.com/apipsicotest/";
    }

    const [idWebChat, setIdWebChat] = useState("");
    const [errorText, setErrorText] = useState(" ");
    const [errorInput, setErrorInput] = useState("psicotest-button");
    const [userData, setUserData] = useState({ name: "", phone: "", isAdditional: false });
    const { preEngagementData } = useSelector((state: AppState) => state.session);
    const dispatch = useDispatch();

    const decodeToken = (token: string) => {
        // Convertir la cadena alfanumérica de vuelta al ID concatenado original
        const idConcatenated = parseInt(token, 36).toString(10);
        // Recuperar el ID original
        const idLength = idConcatenated.length / 3; // Calcula la longitud del ID original
        // Extrae el ID original
        return idConcatenated.substring(0, idLength);
    };

    useEffect(() => {
        const getDataFromSessionStorage = async () => {
            const sessionData = localStorage.getItem("idWebChat") || "";
            let nombre = "";
            let telefono = "";
            let isAdditional = false;

            if (sessionData !== "") {
                setIdWebChat(sessionData);

                await axios
                    .get(`${apiPsicotest}getInfo`, {
                        params: {
                            idWebChat: sessionData
                        }
                    })
                    .then((response) => {
                        console.log(response);
                        nombre =
                            response.data.status === "success" && response.data.nombre !== null
                                ? response.data.nombre
                                : "";
                        telefono =
                            response.data.status === "success" && response.data.telefono !== null
                                ? response.data.telefono
                                : "";
                        isAdditional = response.data.status === "success" && response.data.adicional !== null
                                ? response.data.adicional
                                : false;
                    })
                    .catch((err) => {
                        console.error(err);
                        nombre = "";
                        telefono = "";
                        isAdditional = false;
                    });
            }

            setUserData({
                name: nombre,
                phone: telefono,
                isAdditional
            });

            dispatch(updatePreEngagementData({ name: nombre }));
            dispatch(updatePreEngagementData({ phone: telefono }));
            dispatch(updatePreEngagementData({ isAdditional }));
        };

        getDataFromSessionStorage();
    }, [apiPsicotest, dispatch]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const phoneFormat = /^\d{10}$/;

        if (preEngagementData?.name === undefined || preEngagementData?.name.length <= 0) {
            setErrorText("El nombre es obligatorio");
            setErrorInput("psicotest-button psicotest-error-name");
        } else if (preEngagementData?.phone === undefined || preEngagementData?.phone.length <= 0) {
            setErrorText("El teléfono es obligatorio");
            setErrorInput("psicotest-button psicotest-error-phone");
        } else if (preEngagementData?.phone === undefined || !preEngagementData?.phone.match(phoneFormat)) {
            setErrorText("El teléfono no es válido");
            setErrorInput("psicotest-button psicotest-error-phone");
        } else {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
            try {
                if (idWebChat !== "") {
                    const bodyFormData = new FormData();
                    bodyFormData.append("idWebChat", idWebChat);
                    bodyFormData.append("nombre", preEngagementData?.name);
                    bodyFormData.append("telefono", preEngagementData?.phone);
                    bodyFormData.append("isAdditional", preEngagementData?.isAdditional ? "true" : "false");

                    axios
                        .post(`${apiPsicotest}updateInfo`, bodyFormData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then((response) => {
                            console.log("Actualizado ", response);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }

                const userId = decodeToken(idWebChat);
                const data = await sessionDataHandler.fetchAndStoreNewSession({
                    formData: {
                        friendlyName: preEngagementData?.name,
                        phone: preEngagementData?.phone,
                        query: preEngagementData?.query || "",
                        userId: userId || '',
                        isAdditional: preEngagementData?.isAdditional || false
                    }
                });
                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
            } catch (err) {
                dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
            }
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box
                as="form"
                data-test="pre-engagement-chat-form"
                onSubmit={handleSubmit}
                {...formStyles}
                className={errorInput}
            >
                <Text {...titleStyles} as="h3">
                    ¡Te damos la bienvenida!
                </Text>
                <Text {...introStyles} as="p">
                    Estamos para ayudarte. Por favor ingresa la siguiente información para comenzar.
                </Text>
                {errorText === " " && (
                    <Text as="p" className="psicotest-space-text">
                        {errorText}
                    </Text>
                )}
                <Text as="p" {...introStyles} className="psicotest-error-text">
                    {errorText}
                </Text>
                <Box {...fieldStyles}>
                    <Label htmlFor="name" required>
                        Nombre
                    </Label>
                    <Input
                        type="text"
                        placeholder="Nombre"
                        name="name"
                        data-test="pre-engagement-chat-form-name-input"
                        value={preEngagementData?.name || userData.name}
                        onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                        disabled={userData.name !== ""}
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="phone" required>
                        Teléfono
                    </Label>
                    <Input
                        type="tel"
                        placeholder="5555555555"
                        maxLength={10}
                        name="phone"
                        value={preEngagementData?.phone || userData.phone}
                        onChange={(e) => dispatch(updatePreEngagementData({ phone: e.target.value }))}
                        disabled={userData.phone !== ""}
                    />
                </Box>

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    Comenzar chat
                </Button>
            </Box>
        </>
    );
};
